.App
{
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(5px + 2vmin);
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  color: #d9d9d9;
  display: flex;
  /*flex-direction: row;
  flex-wrap: wrap;*/
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.base {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  max-width: 40%;
  height: auto;
  width: auto\9;
}

.description {
  display: flex;
  flex-direction: row;
  color: grey;
}

.descriptionBlock {
  font-size: calc(5px + 1.5vmin);
  margin-left: calc(3vmin);
  margin-right: calc(3vmin);
}

.social-icon {
  margin-left: 4vmin;
  margin-right: 4vmin;
}

@media only screen and (max-width: 600px) {
  .App {
    flex-direction: column;
  }
  .description {
    flex-direction: column;
  }
  .descriptionBlock {
    /*otherwise we see a space between the flex items*/
    margin: -5px;
  }
}
